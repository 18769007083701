.flex{
    display: flex;
}

.flex-center{
    display: flex;
    justify-content: center;
    align-items: center;
}


.department-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.dept-main-text{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 36px;
}

.departments{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(253, 241, 239, 0.333);
    padding: 10px;
    margin: 20px;
    border-radius: 8px;
}

.dept-img{
    margin: 12px;
    border-radius: 4px;
}

.dept-img:hover{
    scale: 1.1;
    transition: ease-in-out 300ms;
}

.dept-text{
    margin: 5px;
}


.section{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    background-color:  rgba(150, 245, 199, 0.9);
    justify-content: center;
    align-items: center;
    height: 300px;
}

.section-headder{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 38px;
}

.section-text{
    width: 1200px;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 16px;
}

.strength-elem{
    padding: 20px 80px;
    margin: 20px;
}

.strength-txt{
    margin-top: 0;
    border-bottom: 2px solid orange;
}