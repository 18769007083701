.flex{
  display: flex;
}

.headder{
  display: flex;
  align-items: center;
  background-color: rgb(41, 41, 41);
  color: white;
  height: 50px;
}

.headder-text{
  padding: 20px;
}

.navbar{
  background-color: rgb(156, 232, 156);
  height: 100px;
}

.logo{
  display: flex;
}

#clg-logo{
  width: 80px;
  padding: 10px;
}

.logo-text{
  margin-top: 30px;
}

.nav-link{
  padding: 10px;
  margin-left: 200px;
  margin-top: 0 ;
}

.nav-item{
  color: rgb(0, 0, 0);
  padding: 20px;
  margin-top: 10px;
  cursor: pointer;

}

.nav-item:hover {
  color: rgb(255, 255, 255);
}

.MainImage{
  margin-top:10px;
  width: 100%;
}

.main-text{
  display: flex;
  justify-content: center;
  font-size: 60px;
}