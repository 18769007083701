.flex{
    display: flex;
}

.flex-center{
    display: flex;
    justify-content: center;
    align-items: center;
}


.News-section{
    background-color: rgba(150, 245, 199, 0.9);
    padding: 10px;

}

.News-main-headder{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 38px;
    margin-top: 10px;
}

.news-container{
    background-color: rgba(255, 255, 255, 0.8);
    width: max-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 5px;
    padding: 0;
    border-radius: 10px;
}

.news-headder{
    /* margin-top: 100px; */
    color: rgb(255, 140, 0);
    text-align: center;            
}

.news-subtext{
    width: 400px;
    text-align: center;
}

.news-image{
   aspect-ratio: auto;
   width: 350px;
   padding: 40px;
   border-radius: 50px;
}

.news-image:hover{
    scale: 1.1;
    transition: ease-in-out 1s;
}

.button-text{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: aliceblue;
    width: 200px;
    height: 60px;
    border-radius: 20px;
    cursor: pointer;
}

.footer{
    background-color:  rgba(150, 245, 199, 0.9);
}


